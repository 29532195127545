<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Add a Product</p>
        <v-row>
          <v-col
            cols="12" md="6"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedCategory"
              ref="selectedCategory"
              :items="categoryItems"
              item-text="name"
              item-value="id"
              outlined dense
              label="Select Product Category"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field
              v-model="product_name"
              outlined dense ref="product_name"
              label="Product Name"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field
              type="number"
              v-model="supplier_contact"
              outlined dense ref="supplier_contact"
              label="Product Inquiry Contact"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field
              type="number"
              v-model="product_price"
              outlined dense ref="product_price"
              label="Product Selling Price"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field
              type="number"
              v-model="product_wholesale_price"
              outlined dense ref="product_wholesale_price"
              label="Product Wholesale Price"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-file-input
              show-size
              v-model="product_cover_image"
              ref="product_cover_image"
              truncate-length="18"
              style="cursor: pointer"
              label="Choose Cover Image"
            ></v-file-input>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field
              type="number"
              v-model="product_no_stock"
              outlined dense ref="product_no_stock"
              label="Number in Stock"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-file-input
              show-size
              v-model="product_video"
              ref="product_video"
              truncate-length="18"
              style="cursor: pointer"
              label="Choose Product Video"
            ></v-file-input>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-file-input
              show-size multiple
              v-model="product_images"
              ref="product_images"
              truncate-length="18"
              style="cursor: pointer"
              label="Choose Product Images"
            ></v-file-input>
          </v-col>
          

          <v-col
            cols="12"
          >
            <v-text-field
              v-model="key_words"
              ref="key_words"
              outlined
              dense
              label="Key Words"
            ></v-text-field>
            <small>Enter keywords and seperate them with commas</small>
          </v-col>
          <v-col cols="12" class="pb-0 mb-0"><h3>Product Description:</h3></v-col>
          <v-col cols="12" class="py-0 my-1">
            <vue-editor ref="product_description" v-model="product_description" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="addProduct()"
        >
          Add Product
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import Spinner from "@/components/Spinner.vue";
import { VueEditor } from "vue2-editor";
import axios from 'axios'

export default {
  components: {
    Spinner,
    VueEditor,
  },

  data() {
    return {
      loading: false,
      product_name: "",
      supplier_contact: "237",
      selectedCategory: "",
      product_description: "",
      product_video: [],
      product_images: {},
      product_cover_image: [],
      product_no_stock: "",
      product_wholesale_price: "",
      product_price: "",
      key_words: '',
      
      categoryItems: [],
    }
  },
  mounted(){
    document.title = "Manager: Market Place | Add Product"
    this.getProductCategories()
  },

  methods:{
    getProductCategories(){
      axios
        .get('/api/v1/manager/market_place/get/product/categories/')
        .then(response => {
          console.log(response.data)
          this.categoryItems = response.data
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async addProduct(){
        if(this.product_name === ""){
            this.$store.commit('setSnackBarMessage', "Please input product name.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.product_name.focus()  // this causes a an auto fucos to the element
        }
        else if(!this.product_cover_image){
          this.$store.commit('setSnackBarMessage', "Please upload product cover images")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.product_cover_image.focus()  // this causes a an auto fucos to the element
        }
        else if(!this.product_images){
          this.$store.commit('setSnackBarMessage', "Please upload product images")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.product_images.focus()  // this causes a an auto fucos to the element
        }
        else if(!this.supplier_contact){
          this.$store.commit('setSnackBarMessage', "Please input contact which clients will contact for inquires on whatsapp")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.supplier_contact.focus()  // this causes a an auto fucos to the element
        }
        else if(!this.product_video){
          this.$store.commit('setSnackBarMessage', "Please upload product video")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.product_video.focus()  // this causes a an auto fucos to the element
        }
        else if(this.selectedCategory === ""){
            this.$store.commit('setSnackBarMessage', "Please select product category.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.selectedCategory.focus()  // this causes a an auto fucos to the element
        }
        else if(this.product_no_stock === ""){
            this.$store.commit('setSnackBarMessage', "Please input product stock.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.product_no_stock.focus()  // this causes a an auto fucos to the element
        }
        else if(this.product_wholesale_price === ""){
            this.$store.commit('setSnackBarMessage', "Please input product whole sale price.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.product_wholesale_price.focus()  // this causes a an auto fucos to the element
        }
        else if(this.product_price === ""){
            this.$store.commit('setSnackBarMessage', "Please input product price.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.product_price.focus()  // this causes a an auto fucos to the element
        }
        else if(this.key_words === ""){
            this.$store.commit('setSnackBarMessage', "Please input product key words.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.key_words.focus()  // this causes a an auto fucos to the element
        }
        else if(this.product_description === ""){
            this.$store.commit('setSnackBarMessage', "Please product description")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.product_description.focus()  // this causes a an auto fucos to the element
        }
        else {
            this.loading = true
  
            let formData = new FormData()
            formData.append('product_name', this.product_name)
            formData.append('supplier_contact', this.supplier_contact)
            formData.append('product_cover_image', this.product_cover_image)
            formData.append('product_video', this.product_video)
            for(const file_key in this.product_images){
              formData.append('product_images'+String(file_key), this.product_images[file_key])
            }
            formData.append('product_images_length', this.product_images.length)
            formData.append('selectedCategory', this.selectedCategory)
            formData.append('product_no_stock', this.product_no_stock)
            formData.append('product_description', this.product_description)
            formData.append('key_words', this.key_words)
            formData.append('product_price', this.product_price)
            formData.append('product_wholesale_price', this.product_wholesale_price)
            
            await axios
                .post('/api/v1/manager/market_place/add/product/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    console.log(response.data)
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                    if (response.data[0].category === "success"){
                      var navigation = this.$router
                      setTimeout(function(){
                        navigation.push('/marketplace/products/detail/'+response.data[0].product_id)
                      }, 3000)
                    }
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })
            
            this.loading = false
        }

    },
  },
}
</script>
